<template lang="html">
  <el-row :gutter="24">
    <el-col :span="8" :offset="8">
      <div class="login">
        <div class="login-form">
          <h2>修改密码</h2>
          <div class="card-block">
            <el-form @submit.native.prevent ref="form" :model="ivaids" label-width="100px">
              <el-form-item label="请输入新密码">
                <el-input v-model="ivaids.password" type="password"></el-input>
              </el-form-item>
              <el-form-item label="重复新密码">
                <el-input v-model="ivaids.password1" type="password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size='mini' type="primary" :loading="savaloading" @click="onSubmit">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {resetPassword} from "../api/api";
  export default {
    data() {
      return {
        form: {
          userId: this.$route.query.userId,
          resetCode: this.$route.query.resetCode,
          password: '',
        },
        savaloading:false,
        ivaids: {
          password: '',
          password1: ''
        }
      }
    },
    methods: {
      onSubmit() {
        if (this.ivaids.password !== this.ivaids.password1) {
          this.$message.error('两次输入密码不一致');
          return;
        }
        this.form.password = this.ivaids.password;
        let _this=this
        this.savaloading=true
        resetPassword(this.form).then(res=>{
            if(res.success){
                this.$message.success(res.message);
                setTimeout(function(){
                    _this.$router.push({path: '/login'});
                },2000)
            }
            this.savaloading=false
        })
      }
    },
  }
</script>

<style scoped>
  .login-form h2 {
    text-align: center;
    padding: 20px 20px;
  }

  .card-block {
    margin-top: 0 !important;
  }
</style>